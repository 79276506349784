<template>
    <div class="goods-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="待安装" to="/client/goods/uninstalled" />
                <van-tab replace title="进行中" to="/client/goods/installing" />
                <van-tab replace title="已完成" to="/client/goods/installed" />
            </van-tabs>
        </div>
        <div class="goods-list">
            <div class="list-container">
                <div v-for="(item, index) in installList" class="list-item">
                    <div class="item-wrapper">
                        <p class="p1">{{ item.order.order_sn }}</p>
                        <p class="p2">数量：x {{ item.order.number }}</p>
                        <p class="p3" @click="toDetail(item.id)">查看详情</p>
                    </div>
                    <div class="item-wrapper">
                        <p class="p1">安装时间：</p>
                        <p class="p2">{{ item.status_at ? item.status_at : "/" }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_GOODS_INSTALL_LIST } from "@/api/goods";
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

let tabActive = ref(1);

onMounted(() => {
    getDataList();
});

// 获取列表数据
let installList = ref([]);

let getDataList = () => {
    GET_GOODS_INSTALL_LIST({
        status: 1,
        limit: 10000
    }).then((res) => {
        console.log(res.data.data.list);
        installList.value = res.data.data.list;
    });
};

// 前往详情页
let toDetail = (id) => {
    console.log(id);
    $router.push({ name: "ClientGoodsInstallDetail", params: { id } });
};
</script>

<style lang="scss" scoped>
.goods-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .goods-list {
        .list-container {
            width: 100%;
            margin: 10px 0 0 0;
            padding: 0 15px;
            background: #ffffff;

            .list-item {
                padding: 15px 0;
                border-bottom: 1px dashed #eeeeee;

                &:last-child {
                    border-bottom: none;
                }

                .item-wrapper {
                    width: 100%;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    &:first-child {
                        margin-top: 0;
                    }

                    P {
                        &:last-child {
                            margin-left: auto;
                        }
                    }
                }

                :deep(.van-checkbox-group) {
                    width: 100%;

                    .van-checkbox {
                        .van-checkbox__label {
                            width: 100%;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                p {
                    font-size: 12px;
                }

                .p1 {
                    width: 180px;
                    color: #000000;
                }

                .p2 {
                    color: #999999;
                }

                .p3 {
                    margin-left: auto;
                    color: #df5f0b;
                }
            }
        }
    }

    .goods-button {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        border-radius: 0;
    }
}
</style>
